import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/behaviouralhealth.module.css";
import { getAssessmentResultApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import ProgressBar from '@ramonak/react-progress-bar';
import { Box } from '@mui/material';
import Loader from '../../components/common/Loader';

const AssessmentCheckResult = () => {
    const [DetailsData, setDetailsData] = useState<AssessmentResultRes | null>(null)
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();

    const fetchAssessmentResult = async (id: number) => {
        try {
            setLoading(true)
            const result = await getAssessmentResultApi(id);
            if (result?.success) {
                setLoading(false)
                setDetailsData(result?.assessments && result?.assessments[0])
            } else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        if (location?.state?.id) {
            fetchAssessmentResult(location?.state?.id);
        }
    }, [location.pathname])
    if (loading) {
        return <Loader />
    }

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}>
                        <BackIcon />
                        {location?.state?.assessmentName}
                    </h2>
                </div>
                <div className={styles.detailsMain}>
                    {DetailsData?.assessmentTaken && (
                        <div className="mt-8">
                            <div className={styles.progressBarMain}>
                                <div className={styles.resultHead}>
                                    <h3>Results
                                        {DetailsData?.assessmentTaken[0]?.completedDate &&
                                            <span> ({DetailsData?.assessmentTaken && DetailsData?.assessmentTaken[0]?.completedDate})</span>
                                        }
                                    </h3>
                                </div>
                                {
                                    DetailsData?.totalLevels > 1 && DetailsData?.assessmentTaken[0]?.response?.summaries ?
                                        <>
                                            {Object.entries(DetailsData?.assessmentTaken[0]?.response?.summaries).map(([key, value]) => (
                                                <div key={key} className={styles.summaryStyle}>
                                                    <h4>{key}</h4>
                                                    <p>{value}</p>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {
                                                DetailsData?.assessmentTaken && DetailsData?.assessmentTaken[0]?.response?.percentage &&
                                                <div className='progressbarResult'>
                                                    <ProgressBar
                                                        completed={Number(DetailsData?.assessmentTaken[0]?.response?.percentage)}
                                                        maxCompleted={DetailsData?.assessmentTaken[0]?.maxRange}
                                                        bgColor='linear-gradient(90deg, #1FBA5D 0%, #CFDF1A 15.64%, #FFD600 24.6%, #F90 33.8%, #FF6B00 48.67%, #EA0808 100%)'
                                                        baseBgColor='#D9D9D9'
                                                        ariaValuemin={0}
                                                        ariaValuemax={DetailsData?.assessmentTaken[0]?.maxRange}
                                                        isLabelVisible={false}
                                                    />
                                                    <Box className={styles.progressbarScale}>
                                                        <h4>Little / none</h4>
                                                        <h4>Mild</h4>
                                                        <h4>Moderate</h4>
                                                        <h4>High</h4>
                                                        <h4>High to severe</h4>
                                                    </Box>
                                                </div>
                                            }
                                            <div className={styles.progressBarDescription}>
                                                <p><strong>{DetailsData?.assessmentTaken[0]?.response?.result}</strong></p>
                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                    )}
                </div>
            </div>
        </CustomContainer>
    )
}

export default AssessmentCheckResult