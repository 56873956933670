import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/store';
import Layout from '../layout';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const { token, isProfileComplete } = useSelector((state: RootState) => state.auth);
    if (!token) {
        return <Navigate to="/login" replace />;
    }
    if (!isProfileComplete) {
        return (
            <>
                <Layout />
                <Navigate to="/patient/myaccount/profile" replace />
            </>
        );
    }
    return children;
};

export default ProtectedRoute;
