// PatientCard.tsx
import React from 'react';
import styles from '../../assets/styles/careplan.module.css';
import { Images } from '../../utils/functions/Images';
import QrCodeComponent from '../labtest/QrCodeComponent';

interface PatientCardProps {
    data: PatientDetailsCarePlan,
    validFrom: string,
    validUpto: string
}

const PatientCard: React.FC<PatientCardProps> = ({ data, validFrom, validUpto }) => {
    return (
        <div className={styles.PatientCard}>
            <div className='grid grid-cols-4 gap-4'>
                <div className={styles.PCardLeft}>
                    <div className={styles.PImgId}>
                        <img src={data.profileImage ? data.profileImage : Images.avtarIcon} alt="Patient" className={styles.image} />
                        <p>Patient ID</p>
                        <h3>{data.patientId}</h3>
                    </div>
                    <hr className='hrLine hrM4' />
                    <QrCodeComponent />
                </div>
                <div className={`col-span-3 ${styles.PCardRight} relative`}>
                    <div className={styles.logoImg}>
                        <img src={Images.logo} alt="Akos Logo" className={styles.logo} />
                    </div>
                    <div className='flex justify-between flex-col gap-5 h-full'>
                        <div className={styles.PCardDetails}>
                            <h2 className={styles.name}>{data.patientName}</h2>
                            <p>Age - <span>{data.age} years old</span></p>
                            <div className='flex justify-between items-center'>
                                <p>Date of Birth - <span>{data.dateOfBirth}</span></p>
                                <p>Gender - <span>{data.gender}</span></p>
                            </div>
                            <p>Patient Contact Number - <span>{data.phone}</span></p>
                            {/* <p>Aadhar Card Number - <span>{data.}</span></p> */}
                            <p>Address - <span>{data.address}</span></p>
                            <p>Blood Group - <span>{data.bloodGroup}</span></p>
                            <p>Emergency Contact Number - <span>{data.emergency_contact_number}</span></p>
                        </div>
                        <div className={styles.validity}>
                            <p>Valid from <span>{validFrom}</span></p>
                            <p>Valid Upto <span>{validUpto}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientCard;
