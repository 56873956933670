import React, { FC } from 'react'
import styles from "../../assets/styles/labtest.module.css"
import PackageCard from './PackageCard'
import Slider from "react-slick";
import { sliderSettings } from '../../utils/functions/common';

interface Props {
    data: CategoryTests,
    addMore?: boolean,
    closeAddMore?: () => void;
    selectedLab?: number | null;
    fetchLabDetailsForChangeLab?: (id: number) => void
}

const Tests: FC<Props> = ({ data, addMore, closeAddMore, selectedLab, fetchLabDetailsForChangeLab }) => {
    return (
        <>
            {
                Object.keys(data).map((category, id) => {
                    const packages = data[category];
                    const isSliderView = packages.length > 4;

                    return (
                        <div key={id} className={styles.packagesMain}>
                            <div className={styles.packageSection}>
                                <div className={styles.packageHead}>
                                    <h3>{category}</h3>
                                </div>
                                {
                                    isSliderView ?
                                        <Slider {...sliderSettings}>
                                            {
                                                packages.map((packageItem, index) => {
                                                    return (
                                                        <PackageCard
                                                            key={index}
                                                            name={packageItem?.testName}
                                                            categoryName={packageItem?.categoryName}
                                                            categoryId={packageItem?.categoryId}
                                                            labPackageId={packageItem?.testId}
                                                            companyId={packageItem?.companyId}
                                                            description={packageItem?.description}
                                                            type={1}
                                                            addMore={addMore}
                                                            closeAddMore={closeAddMore}
                                                            mode={packageItem?.testMode}
                                                            selectedLab={selectedLab}
                                                            fetchLabDetailsForChangeLab={fetchLabDetailsForChangeLab}
                                                        />
                                                    )
                                                })
                                            }
                                        </Slider>
                                        :
                                        <div className='grid grid-cols-3 gap-6'>
                                            {
                                                packages.map((packageItem, index) => {
                                                    return (
                                                        <PackageCard
                                                            key={index}
                                                            name={packageItem?.testName}
                                                            categoryName={packageItem?.categoryName}
                                                            categoryId={packageItem?.categoryId}
                                                            labPackageId={packageItem?.testId}
                                                            companyId={packageItem?.companyId}
                                                            description={packageItem?.description}
                                                            type={1}
                                                            addMore={addMore}
                                                            closeAddMore={closeAddMore}
                                                            mode={packageItem?.testMode}
                                                            selectedLab={selectedLab}
                                                            fetchLabDetailsForChangeLab={fetchLabDetailsForChangeLab}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Tests