import { Images } from "./Images"

export type dataTypes = {
    id: number,
    label: string,
    color: string,
    position: {
        top: string,
        left: string
    }
}
export const RiskData: dataTypes[] = [
    { id: 1, label: 'Eye care', color: '#F9813E', position: { top: '7%', left: '53%' } },
    { id: 2, label: 'Heart care', color: '#AA2626', position: { top: '23%', left: '56%' } },
    { id: 3, label: 'Liver care', color: '#54A276', position: { top: '31%', left: '42%' } },
    { id: 4, label: 'Bone care', color: '#AA2626', position: { top: '71%', left: '43%' } },
];

export const HealthData: healthDataTypes[] = [
    {
        id: 1,
        title: 'Are you feeling depressed lately?',
        imgUrl: Images.yogaImg,
        buttonText: 'Start Now'
    },
    {
        id: 2,
        title: 'Are you feeling Anxious lately?',
        imgUrl: Images.yogaImg,
        buttonText: 'Start Now'
    },
]
export const cardDataList: CardData[] = [
    { id: 1, title: 'mCHEMIST e-pharmacy', value: '22', subValue: '%OFF', frequency: '' },
    { id: 2, title: 'Care Lab Tests', value: '4', subValue: '', frequency: 'Annually' },
    { id: 3, title: 'Care Appointment', value: '1', subValue: '', frequency: 'per quarter' },
    { id: 4, title: 'General Practitioner', value: '1', subValue: '', frequency: 'per month' },
    { id: 5, title: 'mCHEMIST e-pharmacy', value: '22', subValue: '%OFF', frequency: '' },
    { id: 6, title: 'Care Lab Tests', value: '3/4', subValue: '', frequency: 'Annually' },
    { id: 7, title: 'Care Appointment', value: '1', subValue: '', frequency: 'per quarter' },
  ];

export const patientData: PatientCardData = {
    imageUrl: Images.userImg,
    name: 'John Mery',
    age: 23,
    dob: '12/03/2001',
    gender: 'Male',
    patientContactNumber: '68162464614256',
    aadharCardNumber: '6534 3747 7773',
    address: 'Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit amet, consectetur adipiscing',
    bloodGroup: 'A+',
    emergencyContactNumber: '68162464614256',
    patientId: '100-89-2982',
    qrCodeUrl: Images.QrCodeImg,
    validFrom: '12/02/2020',
    validUpto: '12/02/2029'
  };