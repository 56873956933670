import { AssesmentIcon, CarePlanIcon, CareTeamIcon, DashboardIcon, HealthIcon, HelpIcon, LabTestIcon, MedicalRecordIcon, MedicineIcon, MyAppointmentIcon, PhoneIcon, RPMAlertIcon, SavedDocumentIcon } from "../../utils/functions/Icons";

const SidebarData: SidebarDataType[] = [
    {
        title: 'Dashboard',
        path: '/patient/dashboard',
        icon: DashboardIcon
    },
    {
        title: 'Talk to Doctor',
        path: '/patient/TalkToDoctor',
        icon: PhoneIcon
    },
    {
        title: 'Assessments',
        path: '/patient/Assessments',
        icon: AssesmentIcon
    },
    {
        title: 'Book Lab tests',
        path: '/patient/LabTests',
        icon: LabTestIcon
    },
    {
        title: 'Care team',
        path: '/patient/CareTeam',
        icon: CareTeamIcon
    },
    {
        title: 'Care plan',
        path: '/patient/CarePlan',
        icon: CarePlanIcon
    },
    {
        title: 'Vital Monitoring',
        path: '/patient/RPMAlertManagement',
        icon: RPMAlertIcon
    },
    {
        title: 'Behavioural Health',
        path: '/patient/BehaviouralHealth',
        icon: HealthIcon
    },
    {
        title: 'Medical record',
        path: '/patient/MedicalRecord',
        icon: MedicalRecordIcon
    },
    {
        title: 'My medicine',
        path: '/patient/MyMedicine',
        icon: MedicineIcon
    },
    {
        title: 'My appointments',
        path: '/patient/MyAppointments',
        icon: MyAppointmentIcon
    },
    {
        title: 'Saved Documents',
        path: '/patient/SavedDocuments',
        icon: SavedDocumentIcon
    },
    {
        title: 'Help',
        path: '/patient/Help',
        icon: HelpIcon
    },
];

export default SidebarData;