import styles from "../../assets/styles/teams.module.css"
import { FC } from "react"
import { CheckedIcon } from "../../utils/functions/Icons"
interface Props {
    imgUrl?: string, //remove it
    handleBook?: (data: CareTeamTypes) => void,
    handleCheck?: (id: number) => void,
    id: number,
    isChecked?: boolean,
    isCheckbox?: boolean,
    item?: CareTeamTypes,
    hideBook?: boolean
}
const TeamCard: FC<Props> = ({
    isCheckbox,
    handleBook,
    id,
    handleCheck,
    isChecked,
    item,
    hideBook
}) => {
    const onCardClick = () => {
        if (id && handleCheck) {
            handleCheck(id);
        }
    };
    const handleBookAppointment = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (handleBook && item) {
            handleBook(item);
        }
    };
    return (
        <div className={`${styles.TeamCardMain} ${isCheckbox ? 'cursor-pointer' : ''}`} onClick={onCardClick}>
            <div className={styles.teamContentMain}>
                {
                    isChecked &&
                    <div className={styles.SelectMember}>
                        <div className={styles.checkBoxDiv}>
                            <CheckedIcon />
                        </div>
                    </div>
                }
                <div className={styles.cardImg}>
                    <div className={styles.cardImgMain}>
                        <img src={item?.profilePic} alt="team image" />
                    </div>
                    <h3>{item?.name}</h3>
                    <h4>{item?.speciality}</h4>
                </div>
                <div className={styles.cardOtherContent}>

                    <p>{item?.experience} years of experience</p>
                </div>
            </div>
            {
                !hideBook &&
                <button onClick={handleBookAppointment} className="outLineBtn">Book Now</button>
            }
        </div>
    )
}

export default TeamCard