import { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/teams.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material'
import { SearchIcon} from '../../utils/functions/Icons'
import TeamCard from '../../components/careteams/TeamCard'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/common/Loader'
import NoDataFound from '../../components/common/NoDataFound'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { MyCareTeamApiReducer, closeBookAppointment, openBookAppointment } from '../../utils/store/careteam/CareTeamSlice'
import BookAppointmentModal from '../../components/myAppointments/BookAppointmentModal'

const CareTeam = () => {
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { MyCareTeamData, loading, isModalOpenAppointment, particularData } = useSelector((state: RootState) => state.careteam)

    useEffect(() => {
        if(searchValue){
            const cleanFunction = setTimeout(() => {
                dispatch(MyCareTeamApiReducer({ search: searchValue || '' }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        }else{
            dispatch(MyCareTeamApiReducer({ search: '' }));
        }
    }, [dispatch, searchValue]);

    const handleCloseModal = () => {
        dispatch(closeBookAppointment());
    }
    const handleBook = async (data: CareTeamTypes) => {
        dispatch(openBookAppointment(data))
    }
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>Care Team</h2>
                    <button className='fillButton' onClick={() => navigate('/patient/CareTeam/Create')}>Create my Care team</button>
                </div>
                <div className={styles.bannerSection}></div>
                <div className={styles.SearchBoxMain}>
                    <FormGroup className="customFormGroup">
                        <TextField
                            className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            placeholder='Search by doctor or specialising in'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                </div>
                {
                    loading ? <Loader />
                        :
                        <div className={styles.TeamsSection}>
                            {
                                MyCareTeamData?.length > 0 ?
                                    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
                                        {
                                            MyCareTeamData?.map((item, index) => {
                                                return (
                                                    <TeamCard
                                                        key={index}
                                                        isCheckbox={false}
                                                        id={item?.id}
                                                        item={item}
                                                        handleBook={handleBook}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                    :
                                    <NoDataFound />
                            }
                        </div>
                }
            </div>
            <BookAppointmentModal
                isModalOpen={isModalOpenAppointment}
                handleCloseModal={handleCloseModal}
                particularData={particularData}
            />
        </CustomContainer>
    )
}

export default CareTeam