import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import styles from "../../assets/styles/rpmmanagement.module.css"
import { useNavigate } from 'react-router-dom'
import { ConnectIcon, ConnectedIcon } from '../../utils/functions/Icons'

const RPMManagementTable = () => {
    const navigate = useNavigate();
    const handleViewDetails = () => {
        navigate('/patient/RPMAlertManagement/details')
    }
    const handleConnect = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        alert("Clicked")
    }
    return (
        <TableContainer component={Paper} className='TableContainer'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='custumTableHead'>
                    <TableRow className={styles.completeTableRow}>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Range</TableCell>
                        <TableCell align="right">Current reading</TableCell>
                        <TableCell align="right">Previous Reading</TableCell>
                        <TableCell align="right">Last checked</TableCell>
                        <TableCell align="right">Connect</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.from({ length: 8 }).map((_, index) => {
                            return (
                                <TableRow
                                    key={index}
                                    className={`custumTableRow ${index === 2 ? 'cursor-pointer' : ''}`}
                                    onClick={() => index === 2 && handleViewDetails()}
                                    title ={index === 2 ? 'Click to view details' : ''}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <div className={styles.doctorSec}>
                                            {
                                                index === 2 ?
                                                    <ConnectedIcon />
                                                    :
                                                    <ConnectIcon />
                                            }
                                            Blood Pressure (SYS)
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        50-90 mmHg
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <button className={`${styles.readingBtn} ${index === 0 ? styles.warningBtn : index === 7 ? styles.dangerBtn : styles.normalBtn}`}>50 %</button>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        125 / 100 mmmHg
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        15 Jun, 2024
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <button
                                            className={`${styles.connectText} ${index === 2 ? styles.connectedText : ''}`}
                                            onClick={(event) => handleConnect(event)}
                                        >{index === 0 ? 'Disconnect' : index === 2 ? 'Connected' : 'Connect'}
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RPMManagementTable