import React, { useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/medicalrecord.module.css"
import userImg from "../../assets/images/team/1.png"
import { Tab, Tabs } from '@mui/material'
import { CustomTabPanel, a11yProps } from '../../components/common/CustomTabPanel'
import { useNavigate } from 'react-router-dom'
import { FileIcon } from '../../utils/functions/Icons'
import DoctorAppointmentTable from '../../components/medicalRecord/DoctorAppointmentTable'
import LabTestTable from '../../components/medicalRecord/LabTestTable'
import { getMedicalRecordsApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import Loader from '../../components/common/Loader'
import { Images } from '../../utils/functions/Images'
import { handleViewDocument } from '../../utils/store/common/CommonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import DocumentViewModal from '../../components/savedDocuments/DocumentViewModal'
import NoDataFound from '../../components/common/NoDataFound'
const MedicalRecord = () => {
    const [value, setValue] = useState(0);
    const [medicalRecordsData, setMedicalRecordsData] = useState<MedicalRecordsResponse | null>(null);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const fetchMedicalRecordData = async (id: number) => {
        try {
            setLoading(true)
            const result = await getMedicalRecordsApi(id);
            if (result?.success) {
                setLoading(false)
                setMedicalRecordsData(result?.response)
            }
            else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    useEffect(() => {
        if (LoginData?.companyId) {
            fetchMedicalRecordData(LoginData?.companyId);
        }
    }, [])

    const handleViewPrescription = (item: File) => {
        if (item) {
            dispatch(handleViewDocument({
                fileName: item?.fileName,
                prescriptionURL: item?.fileUrl ? item?.fileUrl : '',
            }))
        }
    }

    if (loading) {
        return <Loader />
    }

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2>Medical Record</h2>
                </div>
                <div className={styles.madicalRecordMain}>
                    {
                        medicalRecordsData?.patientDetails?.name &&
                        <section className={styles.medicalRecordUserSec}>
                            <div className={styles.userNameAndImg}>
                                <div className={styles.userImg}>
                                    <img src={medicalRecordsData?.patientDetails?.profilePic ? medicalRecordsData?.patientDetails?.profilePic : Images.avtarIcon} alt="user image" />
                                </div>
                                {
                                    medicalRecordsData?.patientDetails?.name &&
                                    <div className={styles.usercontent}>
                                        <h3 className='whitespace-nowrap'>{medicalRecordsData?.patientDetails?.name}</h3>
                                        <p>Patient ID: {medicalRecordsData?.patientDetails?.patientId}</p>
                                    </div>
                                }
                            </div>
                            {
                                medicalRecordsData?.patientDetails?.totalAppointments &&
                                <div className={`${styles.userOtherData} ${styles.fixMinWidth}`}>
                                    <h4>Doctor Appointment</h4>
                                    <p>{medicalRecordsData?.patientDetails?.totalAppointments}</p>
                                </div>
                            }
                            <div className={styles.userOtherData}>
                                <h4>Lab tests</h4>
                                <p>{medicalRecordsData?.patientDetails?.labTestsCount}</p>
                            </div>
                            {
                                medicalRecordsData?.patientDetails?.carePlanName &&
                                <div className={styles.userOtherData}>
                                    <h4>Care Plan</h4>
                                    <p>{medicalRecordsData?.patientDetails?.carePlanName}</p>
                                </div>
                            }
                            {
                                medicalRecordsData?.allergies?.length > 0 &&
                                <div className={styles.userOtherData}>
                                    <h4>Allergies</h4>
                                    <p>{medicalRecordsData?.allergies.join(', ')}</p>
                                </div>
                            }
                            {
                                medicalRecordsData?.patientDetails?.diagnosed &&
                                <div className={styles.userOtherData}>
                                    <h4>Diagnosed</h4>
                                    <p>{medicalRecordsData?.patientDetails?.diagnosed}</p>
                                </div>
                            }
                            {
                                medicalRecordsData?.medications?.length > 0 &&
                                <div className={styles.userOtherData}>
                                    <h4>Medications</h4>
                                    <p>{medicalRecordsData?.medications?.join(', ')}</p>
                                </div>
                            }
                        </section>
                    }
                    <section className={`grid grid-cols-4 gap-6 mt-6`}>
                        <section className={`col-span-3 ${styles.appointmentssection}`}>
                            <Tabs value={value} onChange={handleChange} className="custumTabs">
                                <Tab label="Doctor Appointments" {...a11yProps(0)} />
                                <Tab label="Lab tests" {...a11yProps(1)} />
                            </Tabs>
                            <CustomTabPanel value={value} index={0}>
                                {
                                    medicalRecordsData?.appointments && medicalRecordsData?.appointments?.length > 0 ?
                                        <DoctorAppointmentTable appointmentsData={medicalRecordsData?.appointments} />
                                        :
                                        <NoDataFound />

                                }
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                                <LabTestTable BookedLabData ={medicalRecordsData?.labTests} />
                            </CustomTabPanel>
                        </section>
                        {
                            medicalRecordsData?.files && medicalRecordsData?.files?.length > 0 &&
                            <section className={`col-span-1 ${styles.fileSection}`}>
                                <div className={styles.fileHead}>
                                    <h3>Files</h3>
                                </div>
                                <ul className={styles.fileLists}>
                                    {medicalRecordsData?.files?.map((item, index) => {
                                        return (
                                            <li key={index} className='cursor-pointer' onClick={() => handleViewPrescription(item)}>
                                                <FileIcon />
                                                <span>{item?.fileName}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </section>
                        }
                    </section>
                </div>
            </div>
            <DocumentViewModal />
        </CustomContainer>
    )
}

export default MedicalRecord