import React, { useCallback, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/labtest.module.css"
import { BackIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { getLabSummaryReducer } from '../../utils/store/labtest/LabTestSlice'
import Loader from '../../components/common/Loader'
import { getLabOrderApi, labPaymentSuccessApi, labtestPurchaseApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import BtnLoader from '../../components/common/BtnLoader'
import useRazorpay from "react-razorpay";

const LabCartSummary = () => {
    const [LabSummaryData, setLabSummaryData] = useState<OrderSummary | null>(null);
    const [loading, setLoading] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const [cartData, setCartData] = useState({
        price: 0,
        otherCharge: 0,
        discount: 0,
        totalPrice: 0
    })
    const [Razorpay, isLoaded] = useRazorpay();
    const navigate = useNavigate();
    const location = useLocation();

    const fetchSummaryData = async (data: { id: number }) => {
        try {
            setLoading(true)
            const result = await getLabOrderApi(data);
            if (result?.success) {
                setLoading(false)
                const _Data = result?.labOrders && result?.labOrders[0]
                if (_Data) {
                    setLabSummaryData(_Data)
                    const orderDetails = _Data.orderDetails as SummaryOrderDetail[];
                    let price = 0;
                    let otherCharge = 0;
                    let discount = 0;
                    orderDetails.forEach((item) => {
                        if (item.isFree !== 1) {
                            price += parseFloat(item.price);
                            otherCharge += 0;
                            discount += parseFloat(item.discount);
                        }
                    });

                    const totalPrice = price - discount;
                    setCartData({
                        price,
                        otherCharge,
                        discount,
                        totalPrice
                    });

                }
            }
            else {
                setLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    useEffect(() => {
        if (location?.state?.id) {
            fetchSummaryData({ id: location?.state?.id })
        }
    }, [location.pathname]);

    const fetchLabPaymentSuccess = async (data: any, status: string) => {
        try {
            const result = await labPaymentSuccessApi({
                cartId: location?.state?.cartId,
                orderId: location?.state?.id,
                response: data,
                status: status
            })
            if (result?.success) {
                toast.success(result?.message)
                if (status === 'success') {
                    navigate('/patient/LabTests');
                }
            }
        } catch (error) {
            console.log(error, "error")
        }
    }

    const InitiatPayment = useCallback(async (options: any) => {
        try {
            const sendOptions = {
                ...options,
                handler: function (response: any) {
                    fetchLabPaymentSuccess(response, 'success')
                },
            }
            console.log(sendOptions, "sendOptions")
            const rzp1 = new Razorpay(sendOptions);
            rzp1.on("payment.failed", function (response: any) {
                fetchLabPaymentSuccess(response, 'failed')
            });

            rzp1.open();
        } catch (error) {
            console.log(error)
        }
    }, [isLoaded, Razorpay])

    const handleSubmit = async () => {
        try {
            const sendData = {
                "totalPrice": cartData?.totalPrice,
                "patientId": LoginData?.loggedPatientId,
                "cartId": location?.state?.cartId,
                "orderId": location?.state?.id
            }
            setBtnLoading(true)
            const result = await labtestPurchaseApi(sendData);
            if (result?.success) {
                setBtnLoading(false)
                toast.success(result?.message)
                InitiatPayment(result?.paymentDetail)
            }
            else {
                setBtnLoading(false)
                toast.error(result?.message)
            }
        } catch (error: any) {
            setBtnLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }
    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}><BackIcon />Cart Summary</h2>
                </div>
                <div className={styles.cartMain}>
                    <div className='grid grid-cols-7 gap-6'>
                        <section className={`col-span-4 ${styles.cartLeft}`}>
                            <div className='flex flex-col gap-4 mt-6'>
                                {
                                    LabSummaryData?.orderDetails?.map((item, index) => {
                                        return (
                                            <div className={styles.SLListsMain} key={index}>
                                                <div className={styles.testsContent}>
                                                    <h3 className={styles.sNamePrice}>{item?.name} {item?.noOfTest ? `(${item?.noOfTest} tests included)` : ''}</h3>
                                                    <p>({item?.mode})</p>
                                                </div>
                                                <div className={styles.sPriceSec}>
                                                    <p>{item?.isFree === 1 ? 'Free' : `₹ ${item?.total}`}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className={styles.selectLabTest}>
                                    <div className={styles.sBookingAdsec}>
                                        {
                                            LabSummaryData?.bookingAddress &&
                                            <h3>Booking Address: <span>{LabSummaryData?.bookingAddress}</span></h3>
                                        }
                                        {
                                            LabSummaryData?.bookingDate &&
                                            <h3>Booking Date: <span>{LabSummaryData?.bookingDate}</span></h3>
                                        }
                                        {
                                            LabSummaryData?.bookingTime &&
                                            <h3>Booking Time: <span>{LabSummaryData?.bookingTime}</span></h3>
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className={`col-span-3 ${styles.cartRight}`}>

                            {
                                cartData?.totalPrice === 0 ?
                                    <div className={styles.isFreeS}>
                                        <p>This is free for you no need to payment</p>
                                    </div>
                                    :
                                    <>
                                        <div className={styles.makePayHead}>
                                            <h3>Make Payment</h3>
                                        </div>
                                        <ul className={styles.priceSectionMain}>

                                            <li>
                                                <h3>Test price</h3>
                                                <p>₹ {cartData?.price}</p>
                                            </li>
                                            <li>
                                                <h3>Other Charges</h3>
                                                <p>₹ {cartData?.otherCharge}</p>
                                            </li>
                                            <li>
                                                <h3>Total Discount</h3>
                                                <p>- ₹ {cartData?.discount}</p>
                                            </li>
                                            <li className={styles.totalPriceS}>
                                                <h3>Total prices</h3>
                                                <p>₹ {cartData?.totalPrice} </p>
                                            </li>
                                        </ul>
                                    </>
                            }
                            <div className='mt-10'>
                                <button className='fillButton w-full' onClick={handleSubmit}> {btnLoading && <BtnLoader />} {`${cartData?.totalPrice === 0 ? 'Confirm booking' : 'Continue to pay'}`}</button>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default LabCartSummary