import React, { FC } from 'react'
import styles from "../../assets/styles/labtest.module.css"
import { Link, useNavigate } from 'react-router-dom'
import { LabsAddToCartApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import { cartDetailsReducer, setDisableLabChange } from '../../utils/store/labtest/LabTestSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
interface Props {
    name: string,
    categoryName: string,
    categoryId: number,
    noOfTest?: number,
    labPackageId: number,
    companyId: number,
    tests?: string[],
    description?: string,
    type: number,
    addMore: boolean | undefined,
    closeAddMore?: () => void,
    mode: string,
    selectedLab?: number | null,
    fetchLabDetailsForChangeLab?: (id: number) => void
}
const PackageCard: FC<Props> = ({
    name,
    categoryName,
    categoryId,
    noOfTest,
    labPackageId,
    companyId,
    tests,
    description,
    type,
    addMore,
    closeAddMore,
    mode,
    selectedLab,
    fetchLabDetailsForChangeLab
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const { CartItem} = useSelector((state: RootState) => state.labtest)

    const handleAddToCart = async () => {
        try {
            if (LoginData?.loggedPatientId) {
                const result = await LabsAddToCartApi(
                    {
                        type: type,
                        referenceId: labPackageId,
                        patientId: LoginData?.loggedPatientId,
                        mode: mode === "Pathology" ? 1 : 2
                    }
                )
                if (result?.success) {
                    toast.success(result?.message)
                    if (addMore === true && closeAddMore) {
                        dispatch(cartDetailsReducer({ patientId: LoginData?.loggedPatientId, labId: selectedLab ? selectedLab : 0 }))
                        closeAddMore();
                        dispatch(setDisableLabChange(true))
                        if(mode !== "Pathology"){
                            if(CartItem && CartItem[0]?.cartId && fetchLabDetailsForChangeLab){
                                fetchLabDetailsForChangeLab(CartItem && CartItem[0]?.cartId)
                            }
                        }

                    }
                    navigate('/patient/LabTests/Cart')
                } else { toast.error(result?.message) }
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const handleShowMore =() => {
        navigate('/patient/LabTests/details', {
            state: {
                type: type,
                id: labPackageId,
                mode: mode === "Pathology" ? 1 : 2
            }
        })
    }
    return (
        <div className={styles.packageCard}>
            <div className={styles.packageContent}>
                <h3>{name}</h3>
                {
                    noOfTest &&
                    <h4>Include {noOfTest} Tests</h4>
                }
                {
                    description &&
                    <h4>{description}</h4>
                }
                {
                    tests && tests.length > 0 &&
                    <div className={styles.testNames}>
                        {
                            tests?.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {
                                            index < 2 &&
                                            <p>{item}</p>
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        {
                            noOfTest && noOfTest > 3 &&
                            <h6 onClick={handleShowMore}>+ more</h6>
                        }
                    </div>
                }
            </div>
            <div className={styles.packageBtn}>
                <button className='outLineBtn w-full' onClick={handleAddToCart}>Add to Cart</button>
            </div>
        </div>
    )
}

export default PackageCard