import React from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { useNavigate } from 'react-router-dom'
import { BackIcon } from '../../utils/functions/Icons';
import styles from "../../assets/styles/rpmmanagement.module.css"
import RPMDetailsChart from '../../components/rpmmanagement/RPMDetailsChart';

const data = [
    { date: '2024-06-01', systolic: 100, diastolic: 80 },
    { date: '2024-06-02', systolic: 125, diastolic: 85 },
    { date: '2024-06-03', systolic: 130, diastolic: 90 },
    { date: '2024-06-04', systolic: 135, diastolic: 95 },
    { date: '2024-06-05', systolic: 140, diastolic: 100 },
    { date: '2024-06-06', systolic: 145, diastolic: 105 },
    { date: '2024-06-07', systolic: 150, diastolic: 110 },
    { date: '2024-06-08', systolic: 155, diastolic: 115 },
    { date: '2024-06-09', systolic: 160, diastolic: 120 },
    { date: '2024-06-10', systolic: 165, diastolic: 125 },
];

const RPMAlertManagementDetails = () => {
    const navigate = useNavigate();
    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}><BackIcon />Blood Pressure - RPM</h2>
                </div>
                <div className={styles.rpmDetailsPageMain}>

                    <RPMDetailsChart data={data} />
                    <div className={styles.notesSection}>
                        <div className={styles.notesHead}>
                            <h3>Care Navigator Notes </h3>
                        </div>
                        <div className={styles.notesBox}>
                            <ul>
                                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vitae ipsum sed velit efficitur tincidunt. Nullam posuere justo nec nunc feugiat, eget vestibulum </li>
                                <li>purus fringilla. Quisque bibendum augue ac j</li>
                                <li>usto fermentum, nec ullamcorper justo consequat. Duis vitae lorem s</li>
                                <li>
                                    ed eros suscipit ultrices. Sed auctor sapien vitae sapien gravida, vel tincidunt velit feugiat. Integer ultricies risus vel mauris pharetra, sit </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </CustomContainer>
    )
}

export default RPMAlertManagementDetails