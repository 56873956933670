import React, { useEffect, useState } from 'react'
import { addUserToWaitingApi, checkStatusWaitingRoomApi, getGenerateCallIdByPatientApi, getOpenTokRoomKeys, getSavedUserIdApi, getTalkToDoctorApi, getTokenApi } from '../../utils/api/services';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getDeviceInfo } from '../../utils/functions/common';
import WaitingStatusCom from '../../components/chat/WaitingStatusCom';
import Loader from '../../components/common/Loader';
import { useLocation } from 'react-router-dom';
import VideoCall from '../../components/chat/VideoCall';
import UseSocket from '../../utils/functions/useSocket';

const CallNow = () => {
  const apiKey = '46364672';
  const [sessionId, setSessionId] = useState('');
  const [token, setToken] = useState('')
  const [TalkToDoctorData, setTalkToDoctorData] = useState<TalkToDoctorResponse | null>(null);
  const [talkToDoctorDoctorData, setTalkToDoctorDoctorData] = useState<CallNowDoctorDetails[] | []>([])
  const [waitingMsg, setWaitingMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [callid, setCallid] = useState<number | null>(null)
  const { isConnected, connect, disconnect, sendMessage, listenForEvent } = UseSocket();

  const location = useLocation();
  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);

  const fetchOpenTokRoomKeys = async (data: { id: number | undefined }) => {
    try {
      setLoading(true)
      const result = await getOpenTokRoomKeys(data);
      if (result?.status_code === 200) {
        if (result?.result?.length > 0) {
          setSessionId(result?.result[0]?.session)
          setToken(result?.result[0]?.token)
          setLoading(false)
        }
      }
      else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.data?.status_message || error?.message);
    }
  }

  const fetchAddUserToWaiting = async (data: TalkToDoctorResponse | null, userIdData: { call_id: number, staff_id: number }, DocData: CallNowDoctorDetails[] | []) => {
    try {
      const sendData = {
        "docId": DocData && DocData[1]?.connectDoctorId,
        "groupId": DocData && DocData[1]?.groups[0],
        "userId": data?.patientId,
        "symptom": data?.symptoms && data?.symptoms?.length > 0 ? JSON.stringify(data?.symptoms) : "[\"No symptoms\"]",
        "allergy": data?.medical_allergies && data?.medical_allergies?.length ? JSON.stringify(data?.medical_allergies) : "[\"No Allergies\"]",
        "medication": data?.medications && data?.medications?.length ? JSON.stringify(data?.medications) : "[\"No Medications\"]",
        "medical_condition": data?.health_problems && data?.health_problems?.length ? JSON.stringify(data?.health_problems) : "[\"No Pre-existing medical conditions\"]",
        "call_id": userIdData?.call_id,
        "date_of_injury": "",
        "state_of_injury": "",
        "employer_name": "",
        "alias": DocData && DocData[1]?.room_alias
      }
      setLoading(true)
      const result = await addUserToWaitingApi(sendData);
      if (result?.status_code) {
        fetchOpenTokRoomKeys({ id: data?.patientId })
        sendMessage('userjoin', { waitingId: data?.patientId, docid: DocData && DocData[1]?.connectDoctorId, groupId: DocData && DocData[1]?.groups[0] })
        await sendMessage('incomingCallList', { docid: DocData && DocData[1]?.connectDoctorId, groupId: DocData && DocData[1]?.groups[0] })
      }
      else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.data?.status_message || error?.message);
    }
  }
  const fetchSavedUserId = async (data: { email: string | undefined }, ptData: TalkToDoctorResponse | null) => {
    try {
      setLoading(true)
      const result = await getSavedUserIdApi(data)
      if (result?.status_code === 200) {
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.data?.status_message || error?.message);
    }
  }

  const fetchCheckStatusWaitingRoom = async (data: { patient_id: number | undefined }, ptData: TalkToDoctorResponse | null, userIdData: { call_id: number, staff_id: number }, DocData: CallNowDoctorDetails[] | []) => {
    try {
      setLoading(true)
      const result = await checkStatusWaitingRoomApi(data);
      if (result?.status_code === 200 && result?.status_message === "user exists") {
        fetchSavedUserId({ email: ptData?.email }, ptData)
        fetchAddUserToWaiting(ptData, userIdData, DocData)
      }
      else {
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      // toast.error(error?.data?.status_message || error?.message);
      setWaitingMsg(error?.data?.status_message)
    }
  }

  const fetchGenerateCallId = async (data: TalkToDoctorResponse | null, DocData: CallNowDoctorDetails[] | []) => {
    const deviceInfo: string = getDeviceInfo();
    try {
      setLoading(true)
      const sendData = {
        name: data?.name,
        patientId: data?.patientId,
        patientEmail: data?.email,
        patientDob: data?.dateofbirth,
        uuid: data?.uuid,
        phone: data?.phone,
        staffid: 500,
        call_started: moment(new Date()).format('YYYY-MM-DD'),
        room_name: DocData && DocData[1]?.room_alias,
        call_type: "connect",
        device_type: deviceInfo,
        appointmentId: location?.state?.appointmentId ? location?.state?.appointmentId : undefined
      }
      const result = await getGenerateCallIdByPatientApi(sendData);
      if (result?.status_code === 200) {
        fetchCheckStatusWaitingRoom({ patient_id: data?.patientId }, data, result?.result, DocData)
        setCallid(result?.result?.call_id)
      } else {
        setLoading(false)
        toast.error(result?.message)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.data?.errors || error?.message);
    }
  }
  const fetchTalkToDoctorData = async () => {
    try {
      setLoading(true)
      const result = await getTalkToDoctorApi();
      if (result?.success) {
        setTalkToDoctorData(result?.response?.patientDetails);
        setTalkToDoctorDoctorData(result?.response?.doctorDetails);
        setTimeout(() => {
          fetchGenerateCallId(result?.response?.patientDetails, result?.response?.doctorDetails)
        }, 500)
      } else {
        setLoading(false)
        toast.error(result?.message)
      }
    } catch (error: any) {
      setLoading(false)
      toast.error(error?.data?.errors || error?.message);
    }
  }

  useEffect(() => {
    fetchTalkToDoctorData();
  }, [])

  // const apiKey = '46364672';
  // const sessionId = '2_MX40NjM2NDY3Mn5-MTcyMjMyMzYxMTU2N35McHVmVVNZbnVRRUp5ZGhNVHlVVGR2UTF-fn4';
  // const token = 'T1==cGFydG5lcl9pZD00NjM2NDY3MiZzaWc9MTg0OTY0MjI0NDlhYjA5ZjExOGYzM2VhMTcxNTVjOWM3ZGMxYTdiMDpzZXNzaW9uX2lkPTJfTVg0ME5qTTJORFkzTW41LU1UY3lNak15TXpZeE1UVTJOMzVNY0hWbVZWTlpiblZSUlVwNVpHaE5WSGxWVkdSMlVURi1mbjQmY3JlYXRlX3RpbWU9MTcyMjMyMzYxMiZub25jZT0wLjM1MDcxMjU0NDM0NDYyNTMmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTcyMjQxMDAxMiZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ==';

  if (loading) {
    return <Loader />
  }
  return (
    <>
      {
        waitingMsg !== '' ? <WaitingStatusCom message={waitingMsg} />
          :
          <>
            {
              token &&
              <VideoCall apiKey={apiKey} sessionId={sessionId} token={token} TalkToDoctorData={TalkToDoctorData} callid={callid} />
            }
          </>
      }

    </>
  )
}

export default CallNow