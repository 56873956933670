import CustomContainer from '../../components/common/CustomContainer'
import styles from "../../assets/styles/appointments.module.css"
import logo from "../../assets/images/common/mchemistlogo.png"
import bgImg from "../../assets/images/common/completedbg.png"
import { FormGroup, InputAdornment, MenuItem, Select, TextField } from '@mui/material'
import { SearchIcon } from '../../utils/functions/Icons'
import MYMedicineTable from '../../components/mymedicine/MYMedicineTable'
import { useEffect, useState } from 'react'
import Loader from '../../components/common/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { MyMedicineApiReducer } from '../../utils/store/mymedicine/MyMedicineSlice'
import DocumentViewModal from '../../components/savedDocuments/DocumentViewModal'

const MyMedicines = () => {
    const [searchValue, setSearchValue] = useState('')
    const [year, setYear] = useState<string | number>("0");
    const dispatch = useDispatch<AppDispatch>();
    const { loading, AllMedicineData } = useSelector((state: RootState) => state.mymedicine)
    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(MyMedicineApiReducer({ search: searchValue || '', timeFilter: year }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(MyMedicineApiReducer({ search: searchValue || '', timeFilter: year }));
        }
    }, [searchValue, year]);


    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2>My Medicine</h2>
                </div>
                <div className={`mt-6 ${styles.CompletedBannerSec}`}>
                    <div className="grid grid-cols-2 gap-6">
                        <section className={styles.bannerContentSec}>
                            <img src={logo} alt="mchemist logo" />
                            <h4>Upto <span>22% OFF</span> on <span>Medicines and Healthcare Products</span></h4>
                        </section>
                        <section className={styles.bgImgsec}>
                            <img src={bgImg} alt="bgImg" />
                        </section>
                    </div>
                </div>
                <div className={`${styles.SearchBoxMain} flex justify-between items-center w-full max-w-full`}>
                    <FormGroup className="customFormGroup w-1/2">
                        <TextField
                            className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            required
                            placeholder='Search by folder name or document'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                    <FormGroup className="customFormGroup w-36">
                        <Select
                            className={`custumTextField profileInput`}
                            placeholder="Date"
                            defaultValue={"0"}
                            value={year}
                            onChange={(e) => setYear(e.target?.value)}
                        >
                            <MenuItem selected value="0">All</MenuItem>
                            <MenuItem value="1">Last Month</MenuItem>
                            <MenuItem value="2">6 Months</MenuItem>
                            <MenuItem value="3">1 Year</MenuItem>
                        </Select>
                    </FormGroup>
                </div>
                <div className='mt-6'>
                    {
                        loading ? <Loader />
                            :
                            <MYMedicineTable AllMedicineData={AllMedicineData} />
                    }
                </div>
            </div>
            <DocumentViewModal />
        </CustomContainer>
    )
}

export default MyMedicines