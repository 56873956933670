import React, { FormEvent, useEffect, useState } from 'react'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon, CartIcon, SearchIcon } from '../../utils/functions/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import styles from "../../assets/styles/labtest.module.css"
import { FormGroup, InputAdornment, TextField } from '@mui/material'
import Packages from '../../components/labtest/Packages'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../utils/store'
import { cartDetailsReducer, PackageAndTestApiReducer, setLoading } from '../../utils/store/labtest/LabTestSlice'
import Loader from '../../components/common/Loader'
import Tests from '../../components/labtest/Tests'
import UploadPrescription from '../../components/labtest/UploadPrescription'
import { DeletePresFileApi, getLabPrescriptionListApi, LabsAddToCartApi, UploadPrescriptionLabApi } from '../../utils/api/services'
import { toast } from 'react-toastify'

const BookLabtest = () => {
    const [searchValue, setSearchValue] = useState('')
    const [isPresModalOpen, setIsPresModalOpen] = useState(false)
    const [PrescriptionState, setPrescriptionState] = useState<PrescriptionStates>({
        prescriptionFile: '',
        notes: ''
    })
    const [btnLoading, setBtnLoading] = useState(false)
    const [prescriptionList, setPrescriptionList] = useState<PrescriptionRes[] | []>([]);
    const [fileTypePres, setFileTypePres] = useState('pdf');
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<AppDispatch>()
    const { PackagesAndTestData, loading } = useSelector((state: RootState) => state.labtest)
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const handleFileChange = (name: string, file: File | null) => {
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setPrescriptionState((prev) => ({ ...prev, prescriptionFile: fileUrl }))
            const fileType = file?.type === 'application/pdf' ? 'pdf' : 'image'
            setFileTypePres(fileType)
        }
    };
    const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        setPrescriptionState((prev) => ({ ...prev, notes: value }))
    }

    const ClosePresModal = () => {
        setIsPresModalOpen(false)
    }
    const HandleSelectedPres = (file: string) => {
        setPrescriptionState((prev) => ({ ...prev, prescriptionFile: file }))
        const type = file?.split('.')?.pop()?.toLowerCase();
        if (type) {
            setFileTypePres(type)
        }
    }
    const fetchPrescriptionList = async (id: number) => {
        try {
            const result = await getLabPrescriptionListApi(id);
            if (result?.success) {
                setPrescriptionList(result?.prescription)
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        fetchPrescriptionList(16)
    }, [isPresModalOpen])

    const handleCart = () => {
        navigate('/patient/LabTests/Cart')
    }
    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(PackageAndTestApiReducer({ search: searchValue || '', companyId: 1 }));
            }, 1000);
            return () => clearTimeout(cleanFunction);
        } else {
            dispatch(PackageAndTestApiReducer({ search: '', companyId: 1 }));
        }
    }, [dispatch, searchValue]);

    const handleDeletePre = async (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        try {
            const result = await DeletePresFileApi(id)
            if (result?.success) {
                toast.success(result?.message);
                fetchPrescriptionList(16)
            }
            else {
                toast.error(result?.message)
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }

    const submitPrescription = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!PrescriptionState?.prescriptionFile) {
                return toast.error('Prescription file cannot be empty')
            }
            setBtnLoading(true)
            const result = await UploadPrescriptionLabApi({
                prescriptionFile: PrescriptionState?.prescriptionFile,
                notes: PrescriptionState?.notes,
                patientId: 16
            })
            if (result?.success) {
                toast.success(result?.message)
                setBtnLoading(false)
                setPrescriptionState({
                    notes: '',
                    prescriptionFile: ''
                })
                ClosePresModal();
            }
            else {
                setBtnLoading(false)
                toast.success(result?.message)
            }
        } catch (error: any) {
            toast.error(error?.data?.errors || error?.message);
        }
    }
    const fetchCartDetails = async (id: number) => {
        try {
            dispatch(setLoading(true))
            const result = await dispatch(cartDetailsReducer({ patientId: id }))
            if (result?.meta?.requestStatus === "fulfilled") {
                dispatch(setLoading(false))
                if ((result?.payload as any[])?.length > 0) {
                    navigate('/patient/LabTests/Cart')
                }
            }
            else {
                dispatch(setLoading(false))
            }
        } catch (error) {
            dispatch(setLoading(false))
        }
    }
    useEffect(() => {
        if (LoginData?.loggedPatientId) {
            fetchCartDetails(LoginData?.loggedPatientId);
        }
    }, [location.pathname])

    return (
        <CustomContainer>
            <div className='AllPageMain'>
                <div className='HeadAndButton'>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}><BackIcon />Book Lab tests</h2>
                    <div className={styles.uploadPreandCartBtn}>
                        <button className='fillButton' onClick={() => setIsPresModalOpen(true)}>Upload Prescription</button>
                        <button className='outLineBtn' onClick={handleCart}><CartIcon />Cart</button>
                    </div>
                </div>
                <div className={styles.BookLabbannerSection}></div>
                <div className={styles.bookLabTestMain}>
                    <div className={styles.SearchBoxMain}>
                        <FormGroup className="customFormGroup">
                            <TextField
                                className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                                id="searchValue"
                                name="searchValue"
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                                placeholder='Search by test name or diseases'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormGroup>
                    </div>
                    {
                        loading ? <Loader />
                            :
                            <section className={styles.packageSections}>
                                <Packages
                                    data={PackagesAndTestData.categoryPackages}
                                />
                                <Tests
                                    data={PackagesAndTestData.categoryTests}
                                />
                            </section>
                    }
                </div>
            </div>
            <UploadPrescription
                isPresModalOpen={isPresModalOpen}
                ClosePresModal={ClosePresModal}
                handleFileChange={handleFileChange}
                PrescriptionState={PrescriptionState}
                handleNotesChange={handleNotesChange}
                submitPrescription={submitPrescription}
                prescriptionList={prescriptionList}
                fileTypePres={fileTypePres}
                HandleSelectedPres={HandleSelectedPres}
                handleDeletePre={handleDeletePre}
            />
        </CustomContainer>
    )
}

export default BookLabtest