import axios from "axios";
import axiosWithAuth from "./axiosInterceptor";
import axiosNoAuth from "./axiosNoAuth";
import {
  ADD_USERTO_WAITING,
  APPOINTMENT_DETAILS,
  BOOK_APPOINTMENT,
  CANCEL_APPOINTMENT,
  CARE_TEAM_CREATE,
  CHANGE_PASSWORD,
  CHECK_STATUS_WAITING_ROOM,
  CREATE_BULK,
  DELETE_PRES_FILE,
  FORGOT_PASSWORD,
  GENERATE_CALLID_BY_PATIENT,
  GET_OPENTOK_ROOM_KEYS,
  GENERATE_TOKEN,
  GET_APPOINTMENT,
  GET_ASSESSMENTS,
  GET_CARE_PLAN,
  GET_CART_DETAILS,
  GET_CITY,
  GET_DASHBOARD,
  GET_DOCTORS_LIST,
  GET_FILES,
  GET_FOLDER,
  GET_LAB_CITIES,
  GET_LAB_ORDERS,
  GET_LAB_PRESCRIPTION,
  GET_MASTER,
  GET_MEDICAL_RECORDS,
  GET_MY_CARETEAM,
  GET_MY_MEDICINE,
  GET_PACKAGE_AND_TEST,
  GET_PROFILE,
  GET_SAVED_USER_ID,
  GET_STATE,
  GET_TALKTO_DOCTOR,
  HELP_FAQ,
  HELP_SEND_EMAIL,
  LAB_BRANCHES,
  LAB_LIST_BY_CITY,
  LAB_ORDER,
  LABS_ADDTO_CART,
  LOGIN,
  REMOVE_CART_ITEM,
  RESCHEDULE_APPOINTMENT,
  RESEND_OTP,
  TEST_AND_PACKAGE_BYLAB,
  UPLOAD_PRESCRIPTION,
  VERIFY_OTP,
  CALL_END_BY_PATIENT,
  DISCONNECT_REASON,
  PATIENT_DETAILS_CHECK,
  GET_BOOKED_LABTEST,
  GET_TESTOR_PACKAGE_DETAILS,
  FIX_LAB_ON_CART,
  REMOVE_ALL_CART,
  LAB_DETAILSBY_CART_FOR_CHANGELAB,
  PURCHASE_LABTEST,
  UPDATE_PROFILE_ON_CALL,
  CARE_NAVIGATOR_PATIENT_SIGNIN,
  SAVE_NOTES_CARE_NAVIGATOR,
  SSO_MCHEMIST_LOGIN,
  CHAT_LIST_USERS,
  CHAT_HISTORY,
  CHAT_UPDATE_READ_STATUS,
  REMOVE_NOTIFICATION,
  GET_NOTIFICATION,
  UPLOAD_FILE,
  GET_BEHAVIOURALHEALTH,
  GET_ASSESSMENTS_DETAILS,
  GET_ASSESSMENTS_COMPLETED,
  GET_ASSESSMENTS_RESULT,
  LAB_PAYMENT_SUCCESS,
  CHANGE_PASSWORD_INSIDE,
} from "./routes";
import axiosAuthConnect from "./connectInterceptor";

interface ApiResponse<T> {
  success: boolean;
  message: string;
  data: T;
  [key: string]: any;
}
const handleApiError = (error: any) => {
  if (error.response) {
    throw {
      status: error.response.status,
      message: error.response.data.message || "An error occurred",
      data: error.response.data,
    };
  } else if (error.request) {
    throw {
      message:
        "No response received from server. Please check your network connection.",
    };
  } else {
    throw {
      message: error.message,
    };
  }
};
export const loginApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(LOGIN, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const forgotPasswordApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(FORGOT_PASSWORD, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const verifyOtpApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(VERIFY_OTP, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const changePasswordApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(CHANGE_PASSWORD, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const changePasswordInsideApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CHANGE_PASSWORD_INSIDE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const resendOtpApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosNoAuth.post(RESEND_OTP, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const helpFaqApi = async (data?: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      HELP_FAQ,
      data && {
        params: data,
      }
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const helpSendEmailApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(HELP_SEND_EMAIL, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAssessmentsApi = async (data: {
  type: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_ASSESSMENTS, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAssessmentDetailsApi = async (data: {
  id: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      `${GET_ASSESSMENTS_DETAILS}/${data?.id}`
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getBehaviouralHealthDetailsApi = async (data: {
  id: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      `${GET_BEHAVIOURALHEALTH}/${data?.id}`
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getMyCareTeamApi = async (
  data?: any
): Promise<ApiResponse<any>> => {
  try {
    const config = data.search ? { params: { search: data.search } } : {};
    const response = await axiosWithAuth.get(GET_MY_CARETEAM, config);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getDoctorsApi = async (data: {
  id: number;
  search?: string;
}): Promise<ApiResponse<any>> => {
  try {
    const params = data.search
      ? { params: { search: data.search } }
      : undefined;
    const response = await axiosWithAuth.get(
      `${GET_DOCTORS_LIST}/${data?.id}`,
      params
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const createTeamApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CARE_TEAM_CREATE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const bookAppointmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(BOOK_APPOINTMENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getMedicineApi = async (data?: {
  search: string;
  timeFilter: string | number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        search: data?.search ? data.search : undefined,
        timeFilter: data?.timeFilter !== "0" ? data?.timeFilter : undefined,
      },
    };
    const response = await axiosWithAuth.get(GET_MY_MEDICINE, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAppointmentApi = async (data?: {
  search: string;
  id: number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = data?.search
      ? { params: { search: data.search } }
      : undefined;
    const response = await axiosWithAuth.get(
      `${GET_APPOINTMENT}/${data?.id}`,
      params
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const rescheduleAppointmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(RESCHEDULE_APPOINTMENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const cancelAppointmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CANCEL_APPOINTMENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const AppointmentDetaisApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${APPOINTMENT_DETAILS}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getMedicalRecordsApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_MEDICAL_RECORDS}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getDashboardApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_DASHBOARD);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getTalkToDoctorApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_TALKTO_DOCTOR);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getFolderApi = async (data: {
  search: string;
  page: number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        search: data.search ? data.search : undefined,
        page: data?.page,
      },
    };
    const response = await axiosWithAuth.get(GET_FOLDER, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getFilesApi = async (data: {
  search: string;
  id: number;
  timeFilter: string | number;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        search: data.search ? data.search : undefined,
        folderId: data?.id,
        timeFilter: data?.timeFilter !== "0" ? data?.timeFilter : undefined,
      },
    };
    const response = await axiosWithAuth.get(GET_FILES, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getProfileApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_PROFILE);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateProfileCallNowApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPDATE_PROFILE_ON_CALL, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateProfileApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(GET_PROFILE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const createBulkAssessmentApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CREATE_BULK, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const createBulkBehaviouralApi = async (
  data: any,
  type: { type: number }
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(GET_BEHAVIOURALHEALTH, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const GetMasterApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_MASTER}/${data?.path}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const GetStateApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_STATE);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const GetCityApi = async (data: {
  stateCode: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_CITY, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const GetPackageAndTestApi = async (data: {
  companyId: number;
  search: string;
}): Promise<ApiResponse<any>> => {
  try {
    const params = {
      params: {
        search: data.search ? data.search : undefined,
        companyId: data?.companyId,
      },
    };
    const response = await axiosWithAuth.get(GET_PACKAGE_AND_TEST, params);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const UploadPrescriptionLabApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPLOAD_PRESCRIPTION, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const LabsAddToCartApi = async (data: {
  type: number;
  referenceId: number;
  patientId: number;
  mode: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(LABS_ADDTO_CART, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getCartDetailsApi = async (data: {
  patientId: number | undefined;
  labId?: number;
  cartId?: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(GET_CART_DETAILS, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const removeCartItemApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.delete(`${REMOVE_CART_ITEM}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabCitiesApi = async (): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_LAB_CITIES);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabListByCity = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(LAB_LIST_BY_CITY, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabBranchesApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(LAB_BRANCHES, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getTestandPackageByLabApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(TEST_AND_PACKAGE_BYLAB, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabPrescriptionListApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_LAB_PRESCRIPTION}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const DeletePresFileApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.delete(`${DELETE_PRES_FILE}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const labOrderApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(LAB_ORDER, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getLabOrderApi = async (data: {
  id: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_LAB_ORDERS}/${data?.id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getCarePlanApi = async (data: {
  companyId: number;
  patientId: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_CARE_PLAN, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getOpenTokRoomKeys = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(GET_OPENTOK_ROOM_KEYS, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getTokenApi = async (id: string): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GENERATE_TOKEN, {
      params: {
        sessionId: id,
      },
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getConnectTokenApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.post(
      `https://newapi.akosmd.in/v1/tokens`,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getGenerateCallIdByPatientApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(
      GENERATE_CALLID_BY_PATIENT,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const checkStatusWaitingRoomApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(
      CHECK_STATUS_WAITING_ROOM,
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getSavedUserIdApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(GET_SAVED_USER_ID, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const addUserToWaitingApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(ADD_USERTO_WAITING, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const callEndByPatientApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(CALL_END_BY_PATIENT, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const disconnectReasonApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosAuthConnect.post(DISCONNECT_REASON, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const PatientDetailsCheckApi = async (
  email: string
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      `${PATIENT_DETAILS_CHECK}/${email}`
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getBookedLabTests = async (data: {
  id: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      `${GET_BOOKED_LABTEST}/${data.id}`
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getTestOrPackageDatailsApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(GET_TESTOR_PACKAGE_DETAILS, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const fixLabOnCartApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.put(FIX_LAB_ON_CART, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const removeAllCartApi = async (id: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.delete(`${REMOVE_ALL_CART}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const labDetailsByCartIdForChangeLabApi = async (
  id: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(
      `${LAB_DETAILSBY_CART_FOR_CHANGELAB}/${id}`
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const labtestPurchaseApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(PURCHASE_LABTEST, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getCareNavigatorPatientApi = async (): Promise<
  ApiResponse<any>
> => {
  try {
    const response = await axiosWithAuth.get(CARE_NAVIGATOR_PATIENT_SIGNIN);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const saveNotesNavigatorApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(SAVE_NOTES_CARE_NAVIGATOR, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const SsoMchemistLoginApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axios.post(
      "https://frontapi.mchemist.com/api/patient/singlesignin",
      data
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getChatUserListApi = async (data: {
  userId: number;
  userType: number;
  search: string | undefined;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(CHAT_LIST_USERS, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getChatHistoryApi = async (data: {
  senderId: number;
  receiverId: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(CHAT_HISTORY, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const updateChatStatusApi = async (data: {
  senderId: number;
  receiverId: number;
  senderType: number;
  receiverType: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(CHAT_UPDATE_READ_STATUS, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const getNotificationApi = async (data: {
  role: string;
  referenceId: number;
  filter: string;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(GET_NOTIFICATION, {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const removeNotificationApi = async (data: {
  notificationId: number;
}): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(REMOVE_NOTIFICATION, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const uploadFileApi = async (data: any): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(UPLOAD_FILE, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAssessmentCompletedApi = async (): Promise<
  ApiResponse<any>
> => {
  try {
    const response = await axiosWithAuth.get(GET_ASSESSMENTS_COMPLETED);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const getAssessmentResultApi = async (
  id: number
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.get(`${GET_ASSESSMENTS_RESULT}/${id}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
export const labPaymentSuccessApi = async (
  data: any
): Promise<ApiResponse<any>> => {
  try {
    const response = await axiosWithAuth.post(LAB_PAYMENT_SUCCESS, data);
    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
