import { Box, FormGroup, FormLabel, Modal } from '@mui/material'
import React, { FormEvent, memo, useEffect, useState } from 'react'
import { CrossIcon } from '../../utils/functions/Icons'
import BtnLoader from '../common/BtnLoader'
import AutocompleteInput from '../common/AutocompleteInput'
import { useSelector } from 'react-redux'
import { RootState } from '../../utils/store'
import { getTalkToDoctorApi, updateProfileApi, updateProfileCallNowApi } from '../../utils/api/services'
import { toast } from 'react-toastify'
import Loader from '../common/Loader'
interface propTypes {
    openProfileModal: boolean,
    closeProfileModal: () => void,
    ConfirmUpdateProfile: () => void,
    btnLoading?: boolean,
}
const UpdateProfileModal: React.FC<propTypes> = ({ openProfileModal, closeProfileModal, ConfirmUpdateProfile, btnLoading }) => {
    const [formState, setFormState] = useState<CallNowPFormState>({
        medications: [],
        medical_allergies: [],
        health_problems: [],
        symptoms: [],
    })
    const [talkToDoctorData, setTalkToDoctorData] = useState(null)
    const { master } = useSelector((state: RootState) => state.profile);
    const { LoginData } = useSelector((state: RootState) => state.auth)
    const handleAutoComplete = (name: keyof FormState, value: any) => {
        setFormState((prev) => ({ ...prev, [name]: value }));
    };

    const fetchTalkToDoctorData = async () => {
        try {
            const result = await getTalkToDoctorApi();
            if (result?.success) {
                const PData = result?.response?.patientDetails;
                if (PData) {
                    setFormState({
                        ...formState,
                        medications: PData?.medications,
                        medical_allergies: PData?.medical_allergies,
                        health_problems: PData?.health_problems,
                        symptoms: PData?.symptoms
                    })
                }
                setTalkToDoctorData(result?.response)
            } else {
                toast.error(result?.message)
            }
        } catch (error: any) {
        }
    }

    useEffect(() => {
        fetchTalkToDoctorData();
    }, [])


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const sendData = {
                patientId: LoginData?.loggedPatientId,
                medications: (formState?.medications ?? []).map((item: string | { medicationName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.medicationName;
                    }
                }),
                medical_allergies: (formState?.medical_allergies ?? []).map((item: string | { allergiesName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.allergiesName;
                    }
                }),
                health_problems: (formState?.health_problems ?? []).map((item: string | { problemName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.problemName;
                    }
                }),

                symptoms: (formState?.symptoms ?? []).map((item: string | { symptomsName: string }) => {
                    if (typeof item === 'string') {
                        return item;
                    } else {
                        return item.symptomsName;
                    }
                })
            };
            const result = await updateProfileCallNowApi(sendData);
            if (result?.success) {
                toast.success(result?.message)
                ConfirmUpdateProfile();
            }
        } catch (error) {

        }
    }
    return (
        <Modal
            open={openProfileModal}
            onClose={closeProfileModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ width: 400 }} className={"ModalBoxStyle"}>
                <div className="modalHeader">
                    <h3>Update Profile</h3>
                    <CrossIcon onClick={closeProfileModal} />
                </div>
                <div className='ModalContentSection'>
                    <div>
                        <form onSubmit={handleSubmit}>
                            <div className='grid grid-cols-1 gap-y-6'>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Current Medication</FormLabel>
                                    <AutocompleteInput
                                        options={master?.medications}
                                        multiple
                                        className="custumTextField profileInput"
                                        value={formState?.medications}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.medicationName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.medicationName}
                                        createOption={(inputValue: string) => {
                                            return { categoryName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('medications', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Current Health problems</FormLabel>
                                    <AutocompleteInput
                                        options={master?.healthProblems}
                                        multiple
                                        className="custumTextField profileInput"
                                        value={formState?.health_problems}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.problemName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.problemName}
                                        createOption={(inputValue: string) => {
                                            return { problemName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('health_problems', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className="customFormLabel">Medical Allergies</FormLabel>
                                    <AutocompleteInput
                                        options={master?.allergies}
                                        multiple
                                        className="custumTextField profileInput"
                                        value={formState?.medical_allergies}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.allergiesName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.allergiesName}
                                        createOption={(inputValue: string) => {
                                            return { allergiesName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('medical_allergies', value)}
                                    />
                                </FormGroup>
                                <FormGroup className="customFormGroup">
                                    <FormLabel className={`customFormLabel`}>Please select the symptoms</FormLabel>
                                    <AutocompleteInput
                                        options={master?.symptoms}
                                        multiple
                                        className="custumTextField profileInput"
                                        value={formState?.symptoms}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : option.symptomsName}
                                        getOptionKey={(option) => typeof option === 'string' ? option : option.symptomsName}
                                        createOption={(inputValue: string) => {
                                            return { symptomsName: inputValue };
                                        }}
                                        onSelect={(value) => handleAutoComplete('symptoms', value)}
                                    />
                                </FormGroup>
                            </div>
                            <div className='confirmationBtns mt-10'>
                                <button className='outLineBtn' onClick={closeProfileModal}>No</button>
                                <button type='submit' className='fillButton' disabled={btnLoading}>{btnLoading && <BtnLoader />} Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default memo(UpdateProfileModal)