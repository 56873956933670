import { useNavigate } from 'react-router-dom'
import CustomContainer from '../../components/common/CustomContainer'
import { BackIcon, SearchIcon } from '../../utils/functions/Icons'
import { FormGroup, InputAdornment, TextField } from '@mui/material';
import styles from "../../assets/styles/appointments.module.css"
import TeamCard from '../../components/careteams/TeamCard';
import TeamImg1 from "../../assets/images/team/1.png"
import TeamImg2 from "../../assets/images/team/2.png"
import TeamImg3 from "../../assets/images/team/3.png"
import TeamImg4 from "../../assets/images/team/4.png"
import BookAppointmentModal from '../../components/myAppointments/BookAppointmentModal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../utils/store';
import { CareTeamApiReducer, MyCareTeamApiReducer, closeBookAppointment, openBookAppointment } from '../../utils/store/careteam/CareTeamSlice';
const BookAppointment = () => {
    const [searchValue, setSearchValue] = useState('')
    const { MyCareTeamData, isModalOpenAppointment, particularData } = useSelector((state: RootState) => state.careteam)

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (searchValue) {
            const cleanFunction = setTimeout(() => {
                dispatch(MyCareTeamApiReducer({
                    search: searchValue
                }))
            }, 1000);
            return () => clearTimeout(cleanFunction)
        }
        else {
            dispatch(MyCareTeamApiReducer({
                search: '',
            }))
        }
    }, [dispatch, searchValue]);

    const handleCloseModal = () => {
        dispatch(closeBookAppointment());
    }
    const handleBook = async (data: CareTeamTypes) => {
        dispatch(openBookAppointment(data))
    }

    return (
        <CustomContainer>
            <div className={'AllPageMain'}>
                <div className={'HeadAndButton'}>
                    <h2 className='flex items-center gap-4 cursor-pointer' onClick={() => navigate(-1)}><BackIcon /> Schedule a appointments</h2>
                </div>
                <div className={styles.SearchBoxMain}>
                    <FormGroup className="customFormGroup">
                        <TextField
                            className={`custumTextField TextFieldAll ${styles.InputStyle}`}
                            id="searchValue"
                            name="searchValue"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            placeholder='Search by doctor or specialising in'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8'>
                    {
                        MyCareTeamData?.map((item, index) => {
                            return (
                                <TeamCard
                                    key={index}
                                    id={item?.id}
                                    handleBook={handleBook}
                                    item={item}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <BookAppointmentModal
                isModalOpen={isModalOpenAppointment}
                handleCloseModal={handleCloseModal}
                particularData={particularData}
            />
        </CustomContainer>
    )
}

export default BookAppointment