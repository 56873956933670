import CustomContainer from "../../components/common/CustomContainer"
import styles from "../../assets/styles/dashboard.module.css"
import userImg from "../../assets/images/team/1.png"
import { ThreeDotsIcon } from "../../utils/functions/Icons"
import xRayIcon from "../../assets/images/common/xray.png"
import RiskMap from "../../components/dashboard/RiskMap"
import { useEffect, useState } from "react"
import UserScore from "../../components/common/UserScore"
import NoAppointment from "../../components/common/NoAppointment"
import DashboardTimeline from "../../components/dashboard/DashboardTimeline"
import { getDashboardApi, saveNotesNavigatorApi } from "../../utils/api/services"
import { toast } from "react-toastify"
import Loader from "../../components/common/Loader"
import { Images } from "../../utils/functions/Images"
import { useSelector } from "react-redux"
import { RootState } from "../../utils/store"
import { FormGroup, FormLabel, TextareaAutosize } from "@mui/material"
import BtnLoader from "../../components/common/BtnLoader"

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState<DashboardResponse | null>(null)
    const [isSeeDetails, setIsSeeDetails] = useState(false);
    const [loading, setLoading] = useState(false)
    const [overAllNotes, setOverAllNotes] = useState('')
    const [isEditNotes, setIsEditNotes] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const { isCareNavigator, LoginData } = useSelector((state: RootState) => state.auth)


    const handleIsSeeDetails = () => {
        setIsSeeDetails(true)
    }

    const fetchDashboardData = async () => {
        try {
            setLoading(true)
            const result = await getDashboardApi();
            if (result?.success) {
                setLoading(false)
                setDashboardData(result?.response)
                if (isCareNavigator) {
                    setOverAllNotes(result?.response?.notes)
                }
            }
            else {
                setLoading(false)
            }
        } catch (error: any) {
            setLoading(false)
            toast.error(error?.data?.errors || error?.message);
        }
    }

    useEffect(() => {
        fetchDashboardData();
    }, [])

    const handleNoteSubmit = async () => {
        if (isEditNotes) {
            try {
                const sendData = {
                    patientId: LoginData?.loggedPatientId,
                    notes: overAllNotes
                }
                setBtnLoading(true)
                const result = await saveNotesNavigatorApi(sendData);
                if (result?.success) {
                    setBtnLoading(false)
                    setIsEditNotes(false)
                }
                else { setBtnLoading(false) }
            } catch (error: any) {
                setBtnLoading(false)
                toast.error(error?.data?.errors || error?.message);

            }
        }
        else {
            setIsEditNotes(true)
        }
    }

    if (loading) {
        return <Loader />
    }
    return (
        <CustomContainer>
            <div className={styles.dashboardMain}>
                <div className="grid grid-cols-1 xl:grid-cols-7 gap-4">
                    <section className={`col-span-5 ${styles.leftSection}`}>
                        {
                            dashboardData?.patientDetails &&
                            <UserScore patientDetails={dashboardData?.patientDetails} healthDetails={dashboardData?.healthDetails} />
                        }
                        <section className={styles.reportSection}>
                            <div className="grid grid-cols-5 gap-6">
                                <div className={` ${isSeeDetails ? 'col-span-3' : 'col-span-4'} ${styles.RLeftSec}`}>
                                    <div className={styles.RLeftMain}>
                                        <div className={styles.riskSuggestMain}>
                                            <div className={styles.highRisk}>
                                                <div className={styles.circle}></div>
                                                <p>High Risk</p>
                                            </div>
                                            <div className={styles.ModerateRisk}>
                                                <div className={styles.circle}></div>
                                                <p>Moderate risk</p>
                                            </div>
                                            <div className={styles.NormalRisk}>
                                                <div className={styles.circle}></div>
                                                <p>Normal</p>
                                            </div>
                                        </div>
                                        <div className={styles.RLeftBodyMain}>
                                            <RiskMap />
                                        </div>
                                    </div>
                                </div>
                                <div className={` ${isSeeDetails ? 'col-span-2' : 'col-span-1'} ${styles.RRightSec}`}>
                                    {
                                        isSeeDetails ?
                                            <div className={styles.RRightMain}>
                                                <div className={styles.overAllRAndD}>
                                                    <h3>Overall Report</h3>
                                                    <button className="fillButton font14Btn">Download</button>
                                                </div>
                                                <div className={styles.reportConMain}>
                                                    <div className={styles.reportCon}>
                                                        <h5>Glucose</h5>
                                                        <h5>93</h5>
                                                        <p>80-105 mg/dl</p>
                                                    </div>
                                                    {Array.from({ length: 2 }).map((_, index) => {
                                                        return (
                                                            <div key={index} className={`${styles.reportCon} ${styles.reportConDanger}`}>
                                                                <h5>Something</h5>
                                                                <h5>140</h5>
                                                                <p>80-105 mg/dl</p>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className={`${styles.reportAlertConMain}`}>
                                                        <div className={`${styles.reportAlertCon}`}>
                                                            <h5>Heart Rate</h5>
                                                            <h5>140</h5>
                                                            <p>65-72 per min</p>
                                                        </div>
                                                        <ul>
                                                            <li>Slightly risk of Heart attack</li>
                                                            <li>You should consult your doctor soon</li>
                                                        </ul>
                                                    </div>
                                                    <div className={styles.reportCon}>
                                                        <h5>Glucose</h5>
                                                        <h5>93</h5>
                                                        <p>80-105 mg/dl</p>
                                                    </div>
                                                    {Array.from({ length: 2 }).map((_, index) => {
                                                        return (
                                                            <div key={index} className={`${styles.reportCon} ${styles.reportConDanger}`}>
                                                                <h5>Something</h5>
                                                                <h5>140</h5>
                                                                <p>80-105 mg/dl</p>
                                                            </div>
                                                        )
                                                    })}
                                                    <div className={styles.reportXrayCon}>
                                                        <div className={styles.xrayImg}>
                                                            <img src={xRayIcon} alt="x-ray image" />
                                                        </div>
                                                        <div className={styles.xRayContentMain}>
                                                            <div className={styles.xRayHAndR}>
                                                                <h4>X-ray</h4>
                                                                <h4>No risk seen</h4>
                                                            </div>
                                                            <p>Date- 12/02/2023</p>
                                                            <p>Lab - Dr. Red path laboratory </p>
                                                        </div>
                                                    </div>
                                                    {Array.from({ length: 5 }).map((_, index) => {
                                                        return (
                                                            <div className={styles.reportCon} key={index}>
                                                                <h5>Glucose</h5>
                                                                <h5>93</h5>
                                                                <p>80-105 mg/dl</p>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            :
                                            <div className={'flex justify-end'}>
                                                <button className="fillButton" onClick={handleIsSeeDetails}>See Details</button>
                                            </div>
                                    }


                                </div>
                            </div>
                        </section>
                    </section>
                    <section className={`col-span-2 ${styles.rightSection}`}>
                        {
                            dashboardData?.doctorDetails?.doctorId &&
                            <section className={styles.upcomingAppointmentsec}>
                                <div className={styles.upcomingAppointmentMain}>
                                    <div className={styles.UAHeadAndDot}>
                                        <div className={styles.UAHead}>
                                            <h3>Upcoming Appointment</h3>
                                            <p>{dashboardData?.doctorDetails?.speciality}</p>
                                        </div>
                                        <div className="pt-2">
                                            <ThreeDotsIcon />
                                        </div>
                                    </div>
                                    <div className={styles.docAndDateSec}>
                                        {
                                            dashboardData?.doctorDetails?.appointmentDate &&
                                            <div className={styles.docDate}>
                                                <p>{`${dashboardData?.doctorDetails?.appointmentDate}`}</p>
                                                <p>{`${dashboardData?.doctorDetails?.appointmentTime}`}</p>
                                            </div>
                                        }
                                        {
                                            dashboardData?.doctorDetails?.name &&
                                            <div className={styles.docNameSec}>
                                                <div className={styles.docImg}>
                                                    <img src={dashboardData?.doctorDetails?.profilePic ? dashboardData?.doctorDetails?.profilePic : Images.avtarIcon} alt="doctor image" />
                                                </div>
                                                <div className={styles.docName}>
                                                    <p>{dashboardData?.doctorDetails?.name}</p>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section>
                        }
                        {
                            dashboardData?.careTeamDetails && dashboardData?.careTeamDetails?.length > 0 &&
                            <section className={styles.careTeamSec}>
                                <div className={styles.AllSecHead}>
                                    <h3>Care Team</h3>
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    {
                                        dashboardData?.careTeamDetails?.map((item, index) => {
                                            return (
                                                <div className={styles.careTeamCon} key={index}>
                                                    <img src={item?.careTeamProfilePic ? item?.careTeamProfilePic : Images.avtarIcon} alt="Team Image" />
                                                    <p>{item?.careTeamDoctorName}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </section>
                        }
                        {
                            dashboardData?.allergies && dashboardData?.allergies?.length > 0 &&
                            <section className={styles.careTeamSec}>
                                <div className={styles.AllSecHead}>
                                    <h3>Allergies</h3>
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    {dashboardData?.allergies?.map((item, index) => {
                                        return (
                                            <div key={index} className={styles.ALLergyCon}>
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        }
                        {
                            dashboardData?.medications && dashboardData?.medications?.length > 0 &&
                            <section className={styles.careTeamSec}>
                                <div className={styles.AllSecHead}>
                                    <h3>Medications</h3>
                                </div>
                                <div className="grid grid-cols-2 gap-2">
                                    {dashboardData?.medications?.map((item, index) => {
                                        return (
                                            <div key={index} className={styles.ALLergyCon}>
                                                <p>{item}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </section>
                        }
                        {
                            dashboardData?.notes &&
                            <section className={styles.careTeamSec}>
                                <div className={styles.AllSecHead}>
                                    <h3>Overall Notes</h3>
                                </div>
                                <div className={styles.NotesC}>
                                    <p dangerouslySetInnerHTML={{ __html: dashboardData?.notes }} />
                                </div>
                            </section>
                        }
                        {
                            isCareNavigator &&
                            <section className={styles.careTeamSec}>
                                <div className={styles.AllSecHead}>
                                    <h3>Overall Notes</h3>
                                </div>
                                <FormGroup className="customFormGroup">
                                    <TextareaAutosize
                                        minRows={2}
                                        className={`custumTextField profileInput`}
                                        name='overAllNotes'
                                        value={overAllNotes}
                                        disabled={isEditNotes ? false : true}
                                        onChange={(e) => setOverAllNotes(e.target.value)}
                                    />
                                </FormGroup>
                                <button onClick={handleNoteSubmit} className={`mt-4 ${isEditNotes ? 'fillButton' : 'outLineBtn'}`}> {btnLoading && <BtnLoader />} {isEditNotes ? 'Save' : 'Edit'}</button>
                            </section>
                        }
                    </section>
                </div>
                {/* <div className={styles.noDataFound}>
                    <NoAppointment />
                    <button className="fillButton">Update Profile</button>
                </div> */}

                {/* <section className={styles.timeLineSection}>
                    <div className={styles.timeLineHead}>
                        <h3>Timeline</h3>
                    </div>
                    <DashboardTimeline />
                </section> */}
            </div>
        </CustomContainer>
    )
}

export default Dashboard